import React, {useState} from 'react'
import {RiSearchLine} from 'react-icons/ri'


import EventTable from '../../components/tables/EventTable';
import Button from '../../components/button/Button';
import { useNavigate } from 'react-router-dom';

function FirstSection() {
    const [type, setType] = useState("all")
    const [searchTerm, setSearchTerm] = useState('');

    const navigate = useNavigate()

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        
      };
    
    const handleType = (type) =>{
        return setType(type)
    }

    // const addEventHandler = (id) => {
    //     navigate(`create-event`)
    // }

    return(
        <>
        <div className="bg-white table__border pt-8">
        <div className={`flex flex-col md:flex-row items-start md:items-center justify-between px-6 
        flex-wrap gap-4 xl:gap-0`}>
            <div className="flex items-center mb-2 md:mb-0 w-full whitespace-nowrap overflow-x-scroll md:overflow-auto">
                <button 
                onClick={() => handleType("upcoming")} 
                className={`text-primary-grey mr-6 pb-3 ${type === "upcoming" && 
                "border-b border-primary font-medium"}`}>
                    Upcoming Events
                </button>

                <button 
                onClick={() => handleType("past")} 
                className={`text-primary-grey mr-6 pb-3 ${type === "past" 
                && "border-b border-primary font-medium"}`}>
                    Past Events
                </button>

                <button 
                onClick={() => handleType("all")} 
                className={`text-primary-grey mr-6 pb-3 ${type === "all" 
                && "border-b border-primary font-medium"}`}>
                    All Events
                </button>
                
            </div>

            <div className="flex gap-6 w-full lg:justify-end items-center flex-wrap">
                <form onSubmit={handleSubmit}>
                    <div className="flex items-center pl-2 search-payments w-full bg-white ">
                        <RiSearchLine classname="text-lg  text-primary-grey"/>
                        <input
                            type="search"
                            value={searchTerm}
                            onChange={handleInputChange}
                            placeholder="Search..."
                            className="p-3 rounded-lg outline-none border-none w-full"
                        />
                    </div>
                    
                </form>

                <Button to="create-event" bgPrimary textWhite shadow  className={`w-fit sm:! text-xs !text-sm`}>
                    + Add New Event
                </Button>
            </div>

        </div>

        <EventTable type={type} searchTerm={searchTerm}/>
        </div>
        </>
    )
}

export default FirstSection