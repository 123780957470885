import {BiGridAlt} from 'react-icons/bi'
import {FiUsers} from 'react-icons/fi'
import {HiOutlineCog} from 'react-icons/hi'
import {BsCreditCard, BsTags} from 'react-icons/bs'
import {RiSuitcaseLine} from 'react-icons/ri'



export const sidebarItems = [
   {
        name:"Overview",
        path:"/dashboard/overview",
        iconObject:"overviewIcon",
        icon:BiGridAlt
    },
    {
        name:"Admins",
        path:"/dashboard/admins",
        iconObject:"staffsIcon",
        icon:FiUsers
    },
    {
        name:"Business",
        path:"/dashboard/organizers",
        iconObject:"staffsIcon",
        icon:FiUsers
    },
    // {
    //     name:"Orders",
    //     path:"/dashboard/orders",
    //     iconObject:"customersIcon",
    //     icon:RiSuitcaseLine
    // },
    {
        name:"Events",
        path:"/dashboard/events",
        iconObject:"disputeIcon",
        icon:BsTags
    },
    {
        name:"Transactions",
        path:"/dashboard/transactions",
        iconObject:"paymentsIcon",
        icon:BsCreditCard
    },
    {
        name:"Settings",
        path:"/dashboard/settings",
        iconObject:"settingsIcon",
        icon:HiOutlineCog
    }
]